<template>
  <div class="allcontens">
    <div class="content">
      <van-form @submit="submit" class="form">
        <van-field placeholder="请填写手机号" v-model="newMobile" input-align="left" name="newMobile" type="tel" label="新手机号码" :rules="[{ required: true, pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, message: '手机号格式错误' }]" >
          <template #button>
            <van-button size="small" type="primary" @click="send" :disabled="second > 0">{{ second > 0 ? second : '发送验证码' }}</van-button>
          </template>
        </van-field>
        <van-field v-model="verCode" name="verCode" label="验证码" type="digit" maxLength="6" placeholder="请输入验证码" input-align="left" :rules="[{ required: true}]" />
        <div style="margin: 16px;" class="row justify-center">
          <van-button round type="info" size="small" class="margin-top-50 next-btn" native-type="submit">修改手机号码</van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>
<script>
  import storage from '@/utils/storage'
  import { bindMobile, sendSmsCode } from '@/api/sign'
  export default {
    name: 'UpdateMobile',
    data() {
      return {
        insuranceNo: '',
        bodyNo: '',
        newMobile: '',
        verCode: '',
        constSecond: 120,
        second: 0
      }
    },
    async created() {
      this.insuranceNo = storage.getItem('insuranceNo')
      this.bodyNo = storage.getItem('bodyNo')
      this.time()
    },
    methods: {
      async send() {
        if (!this.newMobile) {
          this.$toast('请填写手机号码')
          return
        }
        const params = {
          bodyNo: this.bodyNo,
          mobile: this.newMobile,
          codeType: 9 // 验证码类型，9-绑定手机，6-解绑手机
        }
        const res = await sendSmsCode(params)
        if (!res.success) {
          this.$toast.fail(res.respMag);
          return
        }
        storage.setItem('second', this.constSecond)
        this.time()
      },
      time() {
        const _this = this
        const storageSecond = storage.getItem('second')
        if(!storageSecond) {
          this.second = 0
          return
        }
        _this.second = storage.getItem('second') || _this.constSecond
        _this.timer = setInterval(function() {
          _this.second = _this.second - 1
          if (_this.second > 0) {
            storage.setItem('second', _this.second)
          } else {
            storage.setItem('second', 0)
            clearInterval(_this.timer);
          }
        }, 1000)
      },
      async submit() {
        if (!this.verCode || this.verCode.length !== 6) {
          this.$toast('请输入6位数的验证码')
          return
        }
        const params = {
          bodyNo: this.bodyNo,
          verCode: this.verCode
        }
        const res = await bindMobile(params)
        storage.clearItem('second')
        this.timer && clearInterval(this.timer)
        if (!res.success) {
          this.$toast.fail(res.respMag)
          return
        }
        this.$toast.success('手机号码修改成功！')
        // 根据订单来源跳转到不同的地方， h5 录单的还是需要跳转到实名绑卡， 小程序下单跳转到绑卡步骤
        const info = storage.getItem('info')
      
        setTimeout(() => {
          if (res.success) {
            (info && info.policySource === 3) 
            ? this.$router.push({ path: '/wechat_pay/bind_card', query: { insuranceNo: info.insuranceNo } })
            : this.$router.push({ path: '/sign/bank_cardInformation', query: { mobile: this.newMobile } })
          }
        }, 2000)
      }
    },
    destroyed() {
      this.timer && clearInterval(this.timer)
    }
  }
</script>
<style scoped>
  .borderbt {
    border-bottom: 1px solid #e4e7ed;
    padding: 0.1rem 0.2rem 0.1rem 0.2rem;
    font-size: 0.28rem;
  }

  .error {
    margin-left: 2rem;
    color: red;
    padding-top: 3px;
    /* padding-bottom: 0.05rem; */
    visibility: hidden;
  }

  .smsnext {
    margin: 1rem auto;
  }

  .unbind {
    display: flex;
    justify-content: flex-end;
    font-size: 0.3rem;
    margin-top: 0.2rem;
    color: #1989fa;
    margin-right: 0.2rem;
  }

  .bank-code {
    width: 70%;
    border: none;
    padding-left: 0.28rem;
    color: #757575;
    font-size: 0.28rem;
    background-color: #fff;
    outline: none;
    -webkit-appearance: none;
    background-image: url(../../assets/agreement/drop-down.svg);
    background-repeat: no-repeat;
    background-size: 0.3rem 0.3rem;
    background-position-x: 2.5rem;
    background-position-y: 0.06rem;
    /* background-position: 0rem 3rem; */
  }

  a {
    color: #0084fe;
    text-decoration: underline;
  }

  .form>>>.van-dropdown-menu__bar {
    height: auto;
    box-shadow: none;
  }
</style>
